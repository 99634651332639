.education{
  display:flex;
  justify-content: space-between;
  margin-top: 350px;
  padding: 0 80px 60px ;
  gap: 70px;
  margin-bottom: 60px;
  position: relative;
  &::after{
    content:'';
    width: 92%;
    height:1px;
    background-color: rgba(182, 182, 182, 0.8);
    position:absolute;
    bottom:0;
  }
}

@media screen and (max-width: 1200px) {
  .education{
    flex-direction:column;
    margin-top:340px;
    padding: 0 80px 50px ;
    gap: 60px;
    margin-bottom: 50px;
    position: relative;
    &::after{
      width: 710px;
      height:1px;
      background-color:rgba(214, 214, 214, 0.6);
      left: 40px;
    }
  }
}

@media screen and (max-width:768px){
  .education{
    flex-direction:column;
    margin-top:0;
    padding: 0 0 50px 0 ;
    gap: 60px;
    margin-bottom: 0;
    position: relative;
    .randevu{
      display:none;
    }
    &::after{
      width: 330px;
      height:1px;
      background-color:rgba(214, 214, 214, 0.6);
      left: 32px;
    }
  }
}
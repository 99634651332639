.cart-list{
  padding: 20px 60px 60px;
  display:flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 75px 37px;
}

@media screen and (max-width: 768px){
  .cart-list{
    gap: 25px;
  }
}
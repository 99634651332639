@import "../../../assets/scss/vaiables";

footer {
  display: flex;
  flex-direction: column;
  background-color: $color-grey;

  .main-footer {
    padding: 48px 64px;
    display: flex;
    justify-content: space-between;
    align-items: start;
    border-bottom: 1px solid rgba(153, 153, 153, 0.2);

    .logo {
      display: inline-block;
      width: 180px;
      height: 74px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .links-container {
      display: flex;
      flex-direction: column;
      gap: 34px;

      h3 {
        color: #646363;
        font-size: 12px;
        line-height: 20px;
        font-weight: 500;
      }
      .address{
        color: #2D2D2D;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        text-decoration: none;
        transition: all 400ms;

      }
      .links {
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 24px;

        a {
          color: #2D2D2D;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          text-decoration: none;
          transition: all 400ms;

          &:hover {
            opacity: 0.6;
          }
        }

        &.contact {
          li {
            display: flex;
            align-items: start;
            gap: 24px;

            i {
              font-size: 24px;
            }

            span {
              display: flex;
              flex-direction: column;
              gap: 8px;
            }
          }
        }

        &.socials {
          flex-direction: row;

          i {
            font-size: 24px;
          }

          img {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }

  .secondary-footer {
    padding: 40px 64px;
    font-size: 16px;
    line-height: 26px;
    color: rgba(75, 75, 75, 0.7);
    display:flex;
    align-items:center;
    justify-content: space-between;
    .step-logo{
      img{
        width: 180px;
        height:70px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .main-footer{
    gap: 40px;
  }
}

@media screen and (max-width:768px) {
  footer{
    margin-top:90px;
    .main-footer{
      display:flex;
      flex-direction:column;
      align-items: center;
      justify-content: center;
      gap: 0;
      .logo{
        margin-bottom:50px;
      }
      .links-container{
        align-items:center;
        justify-content: center;
        margin-bottom: 70px;
        width: 72%;
        .links{
          display:flex;
          flex-direction:column;
          justify-content: center;
          align-items: center;
          width: 88%;
          &.contact {
            align-items: start;
            li {
              display: flex;
              align-items: center;
              gap: 34px;

              i {
                font-size: 24px;
              }

              span {
                display: flex;
                flex-direction: column;
                gap: 8px;
              }
            }
          }

          a {
            font-weight: 500;
            line-height: 18px;
          }
        }
      }
    }
    .secondary-footer {
      padding:40px 40px;
      gap: 5px;
      .step-logo{
        img{
          width: 120px;
          height:40px;
        }
      }
    }
  }
}
@import "../../../assets/scss/vaiables";
.child-card{
  display:flex;
  flex-direction:column;
  justify-content: center;
  gap: 21px;
}

.cards{
  display:flex;
  align-items:flex-start;
  gap: 21px;
  flex-wrap:wrap;
  justify-content:center;
}

.service-box {
  color: #fff;
  border-radius: 12px;
  padding: 30px;
  width: 290px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 40px rgb(0 0 0 / 6%);
  transition: all 400ms;
  background-color: $main-color;
  span{
    letter-spacing:1px;
  }
}

.service-container{
  display:flex;
  flex-direction:column;
  justify-content:center;
  gap: 17px;
}




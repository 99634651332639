.doctor-card{
  width: 272px;
  height: 311px;
  background: #FFFFFF;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 36px 30px;
  cursor:pointer;
  transition: 400ms transform;
  &:hover{
    transform: scale(1.1);
    padding:20px ;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
    .profil-photo{
      margin-bottom:5px;
      width: 237px;
      height: 210px;
    }
    img{
      border-radius: 6px;
      width:100%;
      height:100%;
      object-fit: fill;

    }
    .null{
      display:grid;
      place-items:center;
    }
    .doctor-name{
      font-size: 24px;
      color: #E23930;
      margin-bottom:6px;
    }
    .role{
      font-size: 18px;
    }
  }
  .profil-photo{
   margin-bottom:26px;
  }

  img{
    width: 123px;
    height: 123px;
    object-fit: fill;
    border-radius: 50%;
  }
  .null-photo{
    width: 123px;
    height: 123px;
    border-radius: 50%;
    font-size: 28px;
    border: 1px solid #f8f8f8;
    color: black;
    display: grid;
    place-items:center;
  }
  .doctor-name{
    font-size: 20px;
    line-height: 150%;
    letter-spacing: -0.02em;
    color: #252525;
    margin-bottom:8px;
  }
  .role{
    font-size: 17px;
    line-height: 150%;
    color: #5A5A5A;
    text-align:center;
  }
}

@media screen and (max-width: 768px) {
  .doctor-card{
    width: 265px;
    height: 304px;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.06);
    padding: 32px 18px;
    .profil-photo{
      margin-bottom:24px;
    }
    img{
      width: 130px;
      height: 130px;
    }
    .doctor-name{
      color: #1A1A1A;
    }
    .role{
      font-size: 16px;
    }
  }
}
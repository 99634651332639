.container {
  padding-top: 74px;
}

@media screen and (max-width: 768px) {
  .container {
    padding-top: 64px;
  }

}

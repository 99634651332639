.edu-work{
  padding-top:20px;
  width:70%;
 .title{
   font-weight: 600;
   font-size: 30px;
   line-height: 150%;
   color: #252525;
   margin-bottom:40px;
 }
  .universities{
    display:flex;
    flex-direction: column;
    justify-content: center;
    gap: 26px;
    li{
      &:before{
        content:'';
        display: block;
        width: 12px;
        height: 12px;
        border-radius: 12px;
        background-color:#ADADAD;
        position:absolute;
        margin-left: -1.5em;
        margin-top: 9px;

      }
      display: flex;
      gap: 30px;
      .year{
        min-width:141px;
        font-weight: 600;
        font-size: 20px;
        line-height: 150%;
        color: #1A1A1A;
      }
      .place{
        max-width:90%;
        font-size: 20px;
        line-height: 150%;
        color: #414141;

      }
    }
  }
}

@media screen and (max-width: 768px){
  .edu-work{
    padding: 0 40px;
    .title{
      font-size: 24px;
      color:#191919;
    }
    .universities{
      padding: 0 0 0 20px;
      li{
        &:before{
          width: 10px;
          height: 10px;
          border-radius: 10px;
          background-color:#DBDBDB;
          margin-left: -1.1em;
          margin-top: 5px;
        }
        .year{
          min-width:90px;
          font-size: 14px;
        }
        .place{
          width:155px;
          font-size: 16px;
        }
      }
    }
  }
}
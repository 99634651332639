.work{
  padding: 0 80px 60px;
  width: 60%;
  position: relative;
  margin-bottom: 60px;

}

@media screen and (max-width: 1200px){
.work{
  width:100%;
  &::after{
    width:710px;
    left:40px;
  }
}
}

@media screen and (max-width: 768px) {
  .work{
    padding: 50px 0 ;
    width: 100%;
    position: relative;
    margin-bottom: 46px;
    &::after{
      width: 330px;
      background-color:rgba(214, 214, 214, 0.6);
      left: 32px;
    }
  }
}
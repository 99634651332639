.contact-comp{
  padding:78px 80px 60px;
  display:flex;
  justify-content: space-between;
  .contact-card{
    width: 289px;
    height: 225px;
    display:flex;
    flex-direction: column;
    gap: 20px;
    .title{
      font-weight: 600;
      font-size: 22px;
      color: #252525;
    }
    .address{
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: #2F2F2F;
      display:flex;
      flex-direction: column;
      .work-date{
        width: 229px;
        height: 62px;
        display:flex;
        justify-content:space-between;
        .time{
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
          color: #1A1A1A;
        }
        .date{
          color: #4C4C4C;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .contact-comp{
    gap: 60px;
  }
}

@media screen and (max-width: 768px) {
  .contact-comp{
    padding:36px 32px 60px;
    flex-direction: column;
    gap: 60px;
  }
}
.doctor-comp{
  width: 92%;
  height:412px;
  padding:30px;
  display:flex;
  align-items:center;
  gap: 40px;
  background: #FFFFFF;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  position:absolute;
  top: 150px;
  left: 70px;
  .left{
    img{
      border-radius:8px;
      width: 300px;
      height: 351px;
    }
  }
  .null-photo{
    border-radius:8px;
    width: 260px;
    height: 351px;
    font-size: 28px;
    border: 1px solid #f8f8f8;
    color: black;
    display: grid;
    place-items:center;
  }
  .right{
    height:100%;
    padding:5px 0;
    display:flex;
    flex-direction:column;
    justify-content: space-between;
    .doctor-info{
    .dr{
      font-size: 18px;
      line-height: 150%;
      color: #636363;
      margin-bottom:4px;
    }
      .name{
        font-weight: 600;
        font-size: 30px;
        line-height: 150%;
        color: #252525;
        margin-bottom:20px;
      }
      .desc{
        font-size: 18px;
        line-height: 150%;
        color: #434343;

      }
    }
    .contact-doctor{
     display:flex;
      flex-direction: column;
      justify-content: center;
      gap: 20px;
      font-weight: 500;
      font-size: 24px;
      line-height: 150%;
      color: #535353;
      .socail-media{
        display:flex;
        align-items: center;
        gap: 25px;
      }
    }

  }
}

@media screen and (max-width: 1200px) {
  .doctor-comp{
    width: 725px;
    left: 50px;
  }
}

@media screen and (max-width: 768px) {
  .doctor-comp{
    width: 100%;
    height:570px;
    padding:34px 52px 20px;
    flex-direction: column;
    align-items:flex-start;
    gap: 22px;
    box-shadow: none;
    border-radius: 0;
    position:static;
    .left{
      img{
        width: 288px;
      }
    }
    .right{
      padding:0;
      .doctor-info{
        margin-bottom: 70px;
        .dr{
          font-size: 16px;
        }
        .name{
          font-size: 24px;
        }
        .desc{
          font-size: 16px;
        }
      }
    }
  }
}
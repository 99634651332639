@import "../../../assets/scss/vaiables";
.menu-card{
  padding:20px 25px 79px;
  background-color: #fff;
  width: 100%;
  height: 100%;
  display:flex;
  flex-direction: column;
  position: relative;
  right: 0;
  .close{
    position:absolute;
    top: 15px;
    right:15px;
    cursor:pointer;
  }

  .menu-title{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin-top:40px;
    padding:0;
    li{
      a{
        color: #535353;
      }

    }

  }
  .mob-button{
    background-color: $main-color;
    border-radius: 10px;
    color: #fff;
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
    width: 100%;
    height: 48px;
    line-height: 48px;
    text-align: center;
    margin-top:44px;
  }
}
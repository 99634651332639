.online-randevu {
  padding: 16px 64px;

  .content {
    background: #F8F8F8;
    border-radius: 20px;
    padding: 0 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 662px;

    .left {
      display: flex;
      flex-direction: column;
      gap: 10px;
      max-width: 600px;

      h3 {
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        color: #575757;
      }

      p {
        font-weight: 700;
        font-size: 50px;
        line-height: 150%;
        letter-spacing: -0.04em;
        color: #252525;
      }

      span {
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.02em;
        color: #585858;
        opacity: 0.9;
      }
    }
  }
}
.p-multiselect-panel {
  .p-multiselect-header {
    display:none
  }
}
@media screen and (max-width: 1200px) {
  .online-randevu{
    .content{
      padding: 0 50px;
      .left{

        p{
          font-size:30px
        }
      }
    }
  }
}
@media screen and (max-width:768px){
  .online-randevu{
    padding: 0;
    .content{
      flex-direction:column;
      border-radius: 0;
      padding: 32px 0 60px ;
      max-height: 1081px;
      height: 881px;
      .left {
        padding: 10px 32px 20px;
        h3{
          display:none;
        }
        h3 {
          font-size: 10px;
        }

        p {
          font-size: 24px;
          font-weight: 600;
          line-height: 36px;
          letter-spacing: -0.04em;
          color: #252525;
        }

        span {
          font-size: 16px;
          line-height: 28px;
          color: #777777;
          opacity: 0.6;
        }

      }
      .right{
        .form{
          min-width: 359px;
          border-radius:0;
          box-shadow:none;
        }
      }
    }
  }
}

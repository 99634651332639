.short-services {
  overflow: auto;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  marquee {
    ul {
      padding: 30px 16px;
      display: flex !important;
      gap: 100px;
    }
  }
}
@import "../../../assets/scss/vaiables";
.p-carousel, .p-carousel-item, .p-carousel-content , .p-carousel-container, .p-carousel-items-container{
  height: 100%;

}
.p-carousel-indicators {
  display: none;
}
.p-carousel{
  flex-direction:row;
}
.p-carousel .p-carousel-content .p-carousel-prev, .p-carousel .p-carousel-content .p-carousel-next {
  display:flex;
  position:absolute;
  width: 36px;
  height: 36px;
  background-color: #9d959599;
  border-radius: 3px;
  z-index:10;
}
.p-carousel-next{
  top: 180px;
  right:10px;

}
.p-carousel-prev{
  top: 180px;
  left:10px;

}
.hero {
  position: relative;
   background-color:#fff;
  height: calc(100vh - 274px);
  .p-carousel-indicators {
    position: absolute !important;
    left: 64px !important;
    top: unset !important;
    bottom: 64px !important;
    z-index: 99;

    button {
      border-radius: 10px !important;
    }

    .p-highlight {
      button {
        box-shadow: none !important;
        background-color: $main-color !important;
      }
    }
  }

  &-item {
    width:100%;
    height: 100%;

    margin-top: 5px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    .right{
      width:60%;
      height: 100%;
      background-color:#fff;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit:cover;
      }
    }

    .word{
      min-height:100px;
      padding:10px 80px;
      width: 40%;
      .title{
        font-weight: 600;
        font-size: 52px;
        line-height: 150%;
        letter-spacing: -0.01em;
        color: $main-color;
        margin-bottom:32px
      }
      .body{
        font-weight: 300;
        font-size: 22px;
        line-height: 28px;
        letter-spacing: -0.02em;
        color: $main-color;
      }
    }
  }
}
@media only screen and (max-width: 1200px) {
  .hero{
    min-height:500px;
    .hero-item{
      .word{
        padding:10px 30px 10px 64px;
        .title{
          font-size:48px;
        }
      }
    }
    .p-carousel-next{
      top: 240px;
      right:10px;

    }
    .p-carousel-prev{
      top: 240px;
      left:10px;

    }
  }
}
@media only  screen and (max-width:768px){
  .hero{
    height: calc(80vh - 241px);
    background-image: none;
    .p-carousel-indicators {
      display: none;
    }
    .p-carousel{
      flex-direction:row;
    }
    .p-carousel .p-carousel-content .p-carousel-prev, .p-carousel .p-carousel-content .p-carousel-next {
      display:flex;
      position:absolute;
      width: 36px;
      height: 36px;
      background: rgba(255, 255, 255, 0.6);
      border-radius: 3px;
      z-index:10;
    }
    .p-carousel-next{
      top: 100px;
      right:10px;

    }
    .p-carousel-prev{
      top: 100px;
      left:10px;

    }
    .hero-item {
      width: 100%;
      flex-direction: column;
      .right{
        width: 100%;
        height: 200px;
        border-radius:0;
        background-color:#fff;
        overflow: hidden;
        margin: 0;
        position: static;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .word{
        position:static;
        width: 100%;
        min-height:100px;
        padding:32px 32px;
        top:290px;
        left: 0;
        .title{
          font-size: 24px;
          line-height: 138%;
        }
        .body{
          font-size: 16px;
        }
      }
    }
  }
}